body
  margin: 0
  padding: 0
  overflow: hidden
  background-color: #e9e9e9

.canvas
  width: 100vw
  height: 100vh
  object-fit: cover

.button-play
  position: fixed
  bottom: 30px
  right: 50%
  margin-right: -70px
  width: 140px
  font-weight: 500
  font-size: 14px
  height: 46px
  border-radius: 9999px
  border: none
  background-color: rgba(255, 255, 255, 0.8)
  transition: transform 0.25s, opacity 0.25s

  &.hidden
    transform: translateY(40px)
    opacity: 0